.custom-cell {
  width: 250px;
  min-height: 20px;
  padding: 10px;
  background-color: #ffffff;
  /* border: 3px solid #d4772a;  */
  text-align: center;
  font-size: 14px;
}

.key-cell {
  width: 260px;
  height: 20px;
  padding: 10px;
  background-color: #5d5d5d;
  /* border: 3px solid #d4772a;  */
  /* text-align: center;  */
  font-size: 13px;
  color: white;
}

.value-cell {
  width: 260px;
  height: 20px;
  padding: 10px;
  background-color: #e9e9e9;
  border: 1px solid #ffffff;
  /* text-align: center;  */
  font-size: 13px;
  color: black;
}

.subtitle-cell {
  color: #d5601a;
  font-weight: 700;
  font-size: 24px;
}

.leasecontractsummary-cell {
  color: #d5601a;
  font-weight: 400;
  font-size: 18px;
}

.section-header-cell {
  background-color: #f8decf;
  font-size: 14px;
}

.print-button {
  /* padding: 10px 20px; */
  padding: 0px 0px;
  height: 40px;
  font-size: 13px;
  color: #000000;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  width: 130px;
  margin-top: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.back-button {
    /* padding: 10px 20px; */
    padding: 0px 0px;
    height: 40px;
    font-size: 13px;
    color: #000000;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    margin-top: 25px;
  }

@media (min-width: 1270px) {
  .key-cell {
    width: 25%;
  }
  .value-cell {
    width: 25%;
  }
}

/* Hide the print and back buttons during printing */
@media print {
  .print-button,
  .back-button,
  .download-button {
    display: none !important;
  }

  /* Ensure the entire table fits within the print view */
  .Spreadsheet__data {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /* Adjust table layout for printing */
  .Spreadsheet {
    width: 100% !important;
    overflow: visible !important;
  }

  /* Other adjustments for print view */
  body {
    margin: 0;
    -webkit-print-color-adjust: exact; /* Ensures colors are printed accurately */
  }
}
