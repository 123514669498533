/* Loader.css */
/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
    border: 3px solid #f0f0f0;
  }